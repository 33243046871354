import { Link, useNavigate } from "react-router-dom";
import { ImgLogoAvcLlantas } from "../images";
import styled from "styled-components";
import { mediaQuery } from "../styles/constants/mediaQuery";

export const DesktopNavbar = ({ setVisibleFormContact }) => {
  const navigate = useNavigate();

  return (
    <WrapperMenuDesktop>
      <div className="content-left">
        <span onClick={() => navigate("/")}>
          <img
            src={ImgLogoAvcLlantas}
            alt="Avc Llantas logo"
            width="100%"
            height="100%"
          />
        </span>
      </div>
      <div className="content-right">
        <ul>
          <li>
            <Link to="/#" rel="noreferrer">
              INICIO
            </Link>
          </li>
          <li>
            <a href="#about-us" rel="noreferrer">
              NOSOTROS
            </a>
          </li>
          <li>
            <a href="#services" rel="noreferrer">
              SERVICIOS
            </a>
          </li>
          <li>
            <button onClick={() => setVisibleFormContact(true)}>
              CONTACTO
            </button>
          </li>
        </ul>
      </div>
    </WrapperMenuDesktop>
  );
};

const WrapperMenuDesktop = styled.nav`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 25% 1fr;
  grid-column-gap: 3rem;
  color: #000;
  border-bottom: 1px solid #fff;
  ${mediaQuery.minHighResolution} {
    grid-column-gap: 7rem;
  }
  .content-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
    img {
      width: 100%;
      max-width: 11rem;
      height: auto;
      object-fit: contain;
      margin-left: 1.5rem;
    }
  }
  .content-right {
    display: flex;
    justify-content: flex-end;
    width: auto;
    padding: 1rem 2rem 1rem 0;
    clip-path: polygon(2.7rem 0, 100% 0%, 100% 100%, 0% 100%);
    margin-top: -1px;
    color: #fff;
    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      color: inherit;
      li {
        margin: auto 2rem;
        button, a{
          position: relative;
          display: flex;
          color: inherit;
          font-size: 1.3rem;
          font-weight: bold;
          background-color: transparent;
          border: none;
          cursor: pointer;
          &::after{
            content: "";
            position: absolute;
            left: 0;
            bottom: 1.5px;
            width: 0;
            height: 2px;
            background-color: #fff;
          }
          &:hover::after{
            width: 100%;
            transition: 0.3s;
          }
        }
      }
    }
  }
`;
