import { Route, Routes, Navigate } from "react-router-dom";
import { BaseLayout } from "../components";
import { Home, ContactSuccess, Page404 } from "../pages";

export const Router = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <BaseLayout>
            <Home />
          </BaseLayout>
        }
      />
      <Route
        exact
        path="/contact-success"
        element={
          <BaseLayout>
            <ContactSuccess />
          </BaseLayout>
        }
      />
      <Route
        exact
        path="/llantas"
        element={<Navigate to="/" replace={true} />}
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
