import styled from "styled-components";
import {
  ImgOthersServices1,
  ImgOthersServices2,
  ImgOthersServices3,
} from "../../images";

export const OtherServices = () => {
  return (
    <Container>
      <CardsCharacters
        image={ImgOthersServices1}
        title="MÁS DE 23 AÑOS DE EXPERIENCIA"
      />
      <CardsCharacters
        image={ImgOthersServices2}
        title="MÁS DE 23 AÑOS DE EXPERIENCIA"
      />
      <CardsCharacters
        image={ImgOthersServices3}
        title="MÁS DE 23 AÑOS DE EXPERIENCIA"
      />
    </Container>
  );
};

const CardsCharacters = ({ image, title }) => {
  return (
    <Cards>
      <img src={image} alt={title} />
      <span>{title}</span>
    </Cards>
  );
};

const Container = styled.div`
  background: #fff;
  padding: 5rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1.5rem;
`;

const Cards = styled.div`
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 45%);
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 5rem;
  }
  span {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 800;
  }
`;
