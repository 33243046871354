import React, { useState } from "react";
import styled from "styled-components";
import { useDevice } from "../../hooks";
import { Drawer } from "./Drawer";
import { Footer } from "./Footer";
import { mediaQuery } from "../../styles/constants/mediaQuery";
import { MobileNavbar } from "../MobileNavbar";
import { FormContact } from "../ui";
import { DesktopNavbar } from "../DesktopNavbar";
import { ButtonsFloating } from "../ui/ButtonsFloating";

export const BaseLayout = ({ children }) => {
  const { isMobile } = useDevice();

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const [visibleFormContact, setVisibleFormContact] = useState(false);

  const onClickVisibleFormContact = (visible = !visibleFormContact) =>
    setVisibleFormContact(visible);

  return (
    <Container>
      <Drawer
        visibleDrawer={visibleDrawer}
        onSetVisibleDrawer={setVisibleDrawer}
      />
      <header className="header">
        {isMobile ? (
          <MobileNavbar setVisibleDrawer={setVisibleDrawer} />
        ) : (
          <DesktopNavbar setVisibleFormContact={setVisibleFormContact} />
        )}
      </header>
      <main className="body">{children}</main>
      <ButtonsFloating />
      <Footer />
      {visibleFormContact && (
        <FormContact
          visibleFormContact={visibleFormContact}
          onClickVisibleFormContact={onClickVisibleFormContact}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  max-width: 1380px;
  margin: auto;
  height: auto;
  position: relative;
  .header {
    position: absolute;
    width: 100%;
    height: auto;
    background: transparent;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
  }
  .footer {
    width: 100%;
    height: auto;
    padding: 1rem;
    background: black;
    color: #fff;
  }
`;
