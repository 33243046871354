import React, { useState } from "react";
import styled from "styled-components";
import {
  AboutUs,
  Carousel,
  FloatingButton,
  FormContact,
  OtherServices,
  Services,
} from "../../components";

export const Home = () => {
  const [visibleFormContact, setVisibleFormContact] = useState(false);

  const onClickVisibleFormContact = (visible = !visibleFormContact) =>
    setVisibleFormContact(visible);

  return (
    <Container>
      <Carousel onClickVisibleFormContact={onClickVisibleFormContact} />
      <AboutUs />
      <WrapperComponents>
        <Services onClickVisibleFormContact={onClickVisibleFormContact} />
      </WrapperComponents>
      <OtherServices />
      {/*<Contact />*/}
      <FloatingButton onClickVisibleFormContact={onClickVisibleFormContact} />
      {visibleFormContact && (
        <FormContact
          visibleFormContact={visibleFormContact}
          onClickVisibleFormContact={onClickVisibleFormContact}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const WrapperComponents = styled.div`
  width: 100%;
  max-width: 1250px;
  height: auto;
  margin: auto;
`;
