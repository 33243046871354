import { faBars } from "@fortawesome/free-solid-svg-icons";
import { ImgLogoAvcLlantas } from "../images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom/dist";
import styled from "styled-components";

export const MobileNavbar = ({ setVisibleDrawer }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="menu-mobile">
        <div className="content-left">
          <span onClick={() => navigate("/")}>
            <img
              src={ImgLogoAvcLlantas}
              alt="Avc Llantas logo"
              width="100%"
              height="100%"
            />
          </span>
        </div>
        <div className="content-right">
          <div className="icon-bar" onClick={() => setVisibleDrawer(true)}>
            <FontAwesomeIcon icon={faBars} size="2x" />
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  .menu-mobile {
    color: #fff;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 1rem;
    .content-left {
      img {
        width: 12rem;
        height: auto;
        object-fit: contain;
        padding: 0.9rem 1.5rem;
      }
    }
    .content-right {
      display: flex;
      justify-content: end;
      .icon-bar {
        width: auto;
        height: 100%;
        padding: 0.7rem;
      }
    }
  }
`;
