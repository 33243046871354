import {
  ReparacionesAros,
  ReparacionesDireccion,
  CamnioAceites,
  ImgAbout,
} from "../images";
export const services = [
  {
    title: "",
    titleLarge: "REPARACIÓN DE DIRECCIÓN, SUSPENSIÓN Y FRENOS",
    banner: ReparacionesDireccion,
  },
  {
    title: "",
    titleLarge: "REPARACIÓN Y PINTURA DE ARO",
    banner: ReparacionesAros,
  },
  {
    title: "",
    titleLarge: "CAMBIO DE ACEITE Y FILTROS",
    banner: CamnioAceites,
  },
];
