import styled from "styled-components";

export const FloatingButton = ({ onClickVisibleFormContact }) => {
  return (
    <Container onClick={() => onClickVisibleFormContact()}>
      Contactanos
    </Container>
  );
};

const Container = styled.button`
  background: #5a5d5e;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 0 0 1rem 1rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 800;
  cursor: pointer;
  position: fixed;
  z-index: 999;
  top: 30%;
  left: -5rem;
  transform: rotate(-90deg);
  box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  &:hover {
    color: #111;
    background: #ccc;
    transition: 0.5s;
  }
`;
