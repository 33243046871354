import React from "react";
import styled from "styled-components";
import { services } from "../../data-list";
import { Service } from "./Service";

export const Services = ({ onClickVisibleFormContact }) => {
  return (
    <Container id="services">
      <h2>NUESTROS SERVICIOS</h2>
      <hr />
      <WrapperServices>
        {services.map((service, index) => (
          <Service
            key={index}
            titleLarge={service.titleLarge}
            banner={service.banner}
            title={service.title}
            onClickVisibleFormContact={onClickVisibleFormContact}
          />
        ))}
      </WrapperServices>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem 1rem 4rem 1rem;
  position: relative;

  h2 {
    color: #fff;
    margin: 5rem 0 1rem 0;
    text-align: center;
    font-weight: bold;
  }

  hr {
    max-width: 23rem;
    width: auto;
    height: 1px;
    margin: 1.2rem auto 5rem auto;
    border: none;
    background: #b3b6b7;
  }
`;

const WrapperServices = styled.div`
  display: grid;
  justify-content: center;
  flex-wrap: wrap;

  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-gap: 1.5rem;
`;
