import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { IconDelivery } from "../../images";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { mediaQuery } from "../../styles/constants/mediaQuery";

export const Contact = () => {
  return (
    <Container id="contact">
      <div className="content-items-left">
        <h2 className="title">CONTÁCTANOS</h2>
        <div className="list-contact">
          <ul>
            <li>
              <div className="item">
                <a href="tel:+51950425945">
                  <FontAwesomeIcon icon={faPhone} size="lg" className="icon" />
                  <span> 950 425 945 </span>
                </a>
              </div>
            </li>
            <li>
              <div className="item">
                <a href="tel:+51931981314">
                  <FontAwesomeIcon icon={faPhone} size="lg" className="icon" />
                  <span> 931 981 314 </span>
                </a>
              </div>
            </li>
            <li>
              <div className="item">
                <a href="mailto:contacto@gamontllanta.com">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="lg"
                    className="icon"
                  />
                  <span>contacto@gamontllanta.com </span>
                </a>
              </div>
            </li>
            <li>
              <div className="item">
                <a href="https://goo.gl/maps/7GAANunX4WZfQqDD7" target="_blank">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    size="lg"
                    className="icon"
                  />
                  <span>Av. Guardia Civil 257 – Chorrillos </span>
                </a>
              </div>
            </li>
            <li>
              <div className="item">
                <a href="https://g.page/Gamontllantas?share" target="_blank">
                  <FontAwesomeIcon icon={faLocationDot} className="icon" />
                  <span>Av. Huaylas 2691 – Chorrillos</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="content-items-right">
        <div className="item-top">
          <div className="item-icon">
            <img
              src={IconDelivery}
              alt="Gamont llanta delivery"
              width="100%"
              height="100%"
            />
          </div>
          <div className="item-txt">
            <h3>DELIVERY</h3>
            <h2>
              <a href="tel:+51950425945">950 425 945</a>
            </h2>
          </div>
        </div>
        <div className="item-bottom">
          <div className="item-title">
            <h3>REDES SOCIALES</h3>
          </div>
          <div className="item-social">
            <a
              href="https://api.whatsapp.com/send/?phone=51950425945&text=Estoy+interesado+en+su+producto+%2F+servicio&app_absent=0"
              target="_blank"
              aria-label="Boton de Whatsapp"
            >
              <FontAwesomeIcon icon={faWhatsapp} size="3x" className="icon" />
            </a>
            <a
              href="https://www.facebook.com/GamontLlantas/"
              target="_blank"
              aria-label="Boton de Facebook"
            >
              <FontAwesomeIcon icon={faFacebook} size="3x" className="icon" />
            </a>
            <a
              href="https://www.instagram.com/gamontllantas/"
              target="_blank"
              aria-label="Boton de Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} size="3x" className="icon" />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  margin: 3rem 0 1rem 0;
  padding: 2rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 2rem;
  background: linear-gradient(
    to bottom,
    #fad249 45%,
    #fad249 45%,
    #df0000 45%,
    #df0000 50%
  );
  ${mediaQuery.minDesktop} {
    background: linear-gradient(63.57deg, #fad249 49.99%, #df0000 49.99%);
    grid-row-gap: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  ul {
    list-style: none;
    margin: 0;
    color: inherit;
    li {
      margin: auto 0.5rem;
      .item {
        color: inherit;
        a {
          color: inherit;
        }
        .icon {
          margin-right: 0.7rem;
        }
      }
    }
  }

  .content-items-left {
    color: #000;
    .title {
      color: inherit;
    }
    .list-contact {
      ul li {
        margin-bottom: 0.7rem;
      }
    }
  }

  .content-items-right {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 0;
    ${mediaQuery.minTablet} {
      grid-row-gap: 3rem;
    }
    .item-top,
    .item-bottom {
      width: 100%;
      height: 100%;
    }
    .item-top {
      display: grid;
      align-items: center;
      grid-template-columns: auto;
      grid-template-rows: 1fr 1fr;
      ${mediaQuery.minMobile} {
        grid-template-columns: auto auto;
        grid-template-rows: 1fr;
      }
      .item-icon {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        img {
          width: 60%;
          height: auto;
          margin-right: 0.7rem;
          margin-bottom: 1rem;
          ${mediaQuery.minTablet} {
            margin-bottom: 0;
            width: 12rem;
          }
        }
      }
      .item-txt {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        h2,
        h3 {
          margin: 0;
          font-weight: 700;
          line-height: 2.3rem;
          ${mediaQuery.minTablet} {
            line-height: 3rem;
          }
        }
        h2 a {
          font-size: 2.7rem;
          color: #fad249;
          ${mediaQuery.minTablet} {
            font-size: 3rem;
          }
        }
        h3 {
          font-size: 1.8rem;
          ${mediaQuery.minTablet} {
            font-size: 2.3rem;
          }
        }
      }
    }
    .item-bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      color: #fff;
      .item-title {
        color: inherit;
        h3 {
          font-size: 1.8rem;
          font-weight: bold;
          margin: 0;
          ${mediaQuery.minTablet} {
            font-size: 2.3rem;
          }
        }
      }
      .item-social {
        a {
          margin-left: 1.7rem;
          color: inherit;
        }
      }
    }
  }
`;
