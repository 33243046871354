import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { ImageDefault } from "../../images";

export const Service = ({
  banner,
  title,
  titleLarge,
  onClickVisibleFormContact,
}) => {
  return (
    <Container>
      <div className="item-picture">
        <img
          src={banner || ImageDefault}
          alt="Image"
          width="100%"
          height="100%"
        />
        <h1 className="item-service-name">{title}</h1>
      </div>
      <div className="item-title-card">
        <h4> {titleLarge} </h4>
      </div>
      <div className="footer-card">
        <div className="blog-divider-wrapper">
          <div className="blog-divider" />
        </div>
        <div className="item-bottom">
          <div className="item-more">
            <div className="bottom-txt">
              <span onClick={() => onClickVisibleFormContact()}>
                <h5>CONTÁCTANOS</h5> <FontAwesomeIcon icon={faPlay} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background: radial-gradient(100% 100% at 50.52% 0%, #686868 0%, #000000 100%);
  box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
  border-radius: 8px;
  min-height: 29rem;
  max-height: 29rem;
  width: 100%;
  overflow: hidden;

  .item-picture {
    position: relative;
    transition: all 0.3s ease;
    z-index: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: "";
      background: rgba(0, 0, 0, 0.35);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    img {
      width: 100%;
      height: 17rem;
      object-fit: cover;
    }
    .item-service-name {
      color: #fff;
      font-size: 3rem;
      text-align: center;
      position: absolute;
      margin: auto;
      text-transform: uppercase;
    }
  }

  .item-title-card {
    width: 100%;
    height: auto;
    min-height: 5.5rem;
    padding: 1rem 0.7rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #5a5d5e;
    font-family: "MontserratRegular";

    h4 {
      margin: 0;
      color: #fff;
    }
  }

  .footer-card {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
    padding: 26px 20px;
    color: #fff;
    background: #5a5d5e;

    .blog-divider-wrapper {
      position: relative;
      width: 100%;
      height: 10px;
      z-index: 0;

      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 93%;
        bottom: 0;
        width: 75px;
        height: 1px;
        background-color: #fff;
        z-index: 1;
        -webkit-transform: rotate(47deg);
        transform-origin: 0% 0%;
        transition: all 0.3s ease;
      }

      .blog-divider {
        &:before {
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 65px;
          height: 6px;
          background-color: #fff;
          z-index: 1;
          transition: all 0.3s ease;
        }

        &:after {
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 93%;
          height: 1px;
          background-color: #fff;
          z-index: 1;
          transition: all 0.3s ease;
        }
      }
    }

    .item-bottom {
      width: 100%;
      height: auto;
      position: relative;

      .item-more {
        position: relative;

        .bottom-txt {
          margin: 1.7rem 0 0 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          span {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #fff;
            &:hover {
              color: #000;
            }

            h5 {
              margin: 0 0.3rem 0 0;
              color: inherit;
            }
          }
        }
      }
    }
  }
`;
