import React from "react";
import styled, { css } from "styled-components";
import { darken } from "polished";

export const ButtonPrimary = ({ children, onClick }) => {
  return (
    <Button onClick={() => onClick()} className="btn-primary">
      {children}
    </Button>
  );
};

const Button = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    background: #080808;
    color: #d9dadb;
    font-size: 1.7rem;
    display: flex;
    margin: auto;
    padding: 0.7rem 2rem;
    border-radius: 1rem;
    border: none;
    &:hover {
      transition: 0.5s;
      background: #333;
    }
  `}
`;
