import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../styles/constants/mediaQuery";
import { ImgCongratulation } from "../../images";
import { useNavigate } from "react-router";
import { ButtonPrimary } from "../../components";
/*import { common } from "../../firebase";
import * as ReactGA from "react-ga";*/

export const ContactSuccess = () => {
  const navigate = useNavigate();

/*  const googleAnalytics = common.googleAnalytics;

  ReactGA.initialize(googleAnalytics.trackingId, { debug: true });

  ReactGA.pageview(window.location.pathname + window.location.search);*/

/*  useEffect(() => {
    ReactGA.event({
      category: "conversion",
      send_to: "AW-10929461629/0kE7CPeIisQDEP2yydso",
    });
  }, []);*/

  return (
    <Container>
      <img
        src={ImgCongratulation}
        className="img-slide"
        alt="Banner gamont llanta"
        width="100%"
        height="100%"
      />
      <div className="content-slide-txt">
        <h1 className="title-banner">GRACIAS POR CONTACTARTE CON NOSOTROS</h1>
        <br />
        <h2>LE RESPONDEREMOS EN BREVE</h2>
        <div className="item-button">
          <ButtonPrimary onClick={() => navigate("/")}>Regresar</ButtonPrimary>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(#070707fc, rgb(0 0 0 / 8%), #070707e3);
  }
  .img-slide {
    width: 100vw !important;
    height: 40rem !important;
    object-fit: cover;
    ${mediaQuery.minTablet} {
      height: auto !important;
    }
  }
  .content-slide-txt {
    position: absolute;
    color: #fff;
    z-index: 500;
    width: 100%;
    bottom: 20%;
    text-align: center;
    .title-banner {
      margin: auto 2rem;
      font-family: "BlackOpsOneRegular", serif;
      font-size: 2rem;
      line-height: 4rem;
      text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);
      ${mediaQuery.minDesktop} {
        font-size: 4rem;
        bottom: 30%;
      }
    }
    h2 {
      text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);
      font-size: 1.5rem;
    }
    .item-button {
      margin-top: 2rem;
    }
  }
`;
