import React from "react";
import styled from "styled-components";
import { ImgAboutAvc } from "../../images";

import { mediaQuery } from "../../styles/constants/mediaQuery";

export const AboutUs = () => {
  return (
    <Container id="about-us">
      <div className="title-top">
        <h2>GARANTÍA INTEGRAL EN TODOS LOS TRABAJOS</h2>
      </div>

      <SectionAbout>
        <div className="left-content">
          <div className="section-title">
            <h2 className="top-title">PROPOCIONAMOS</h2>
            <h3 className="bottom-title">SERVICIOS DE CONFIANZA PARA TI</h3>
          </div>
          <div className="section-image">
            <img src={ImgAboutAvc} alt="about image" />
          </div>
        </div>

        <div className="right-content">
          <div className="description-about">
            <h2>¿QUIENES SOMOS?</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet
              architecto corporis deserunt eveniet illum, iure laudantium modi
              odit tempore tenetur!
            </p>
          </div>
        </div>
      </SectionAbout>
    </Container>
  );
};

const Container = styled.section`
  background: #fff;
  width: 100%;
  .title-top {
    background: #000;
    padding: 2rem 0;
    h2 {
      font-weight: 500;
      font-size: 2.5rem;
      padding: 0 2rem;
      font-family: "Black Ops One", Serif;
    }
  }
`;

const SectionAbout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 1.5rem 2rem 0;

  ${mediaQuery.minDesktop} {
    grid-template-columns: 60% 40%;
  }
  .left-content {
    position: relative;
    display: flex;
    flex-direction: column;
    ${mediaQuery.minTablet} {
      flex-direction: row;
    }
    ${mediaQuery.minDesktop} {
      flex-direction: row;
    }
    .section-title {
      position: initial;
      background: #5a5d5e;
      padding: 1.5rem 1rem;
      width: 100%;
      ${mediaQuery.minTablet} {
        position: absolute;
        top: 10%;
        width: 65%;
      }

      .top-title {
        font-size: 1.5rem;
        font-weight: 900;
      }

      .bottom-title {
        font-size: 2.5rem;
        line-height: 3.5rem;
        font-family: "BlackOpsOneRegular", serif;
        ${mediaQuery.minDesktop} {
          font-size: 3.5rem;
          line-height: 4.8rem;
        }
      }
    }
    .section-image {
      padding: 0 0.5rem;
      position: initial;
      ${mediaQuery.minTablet} {
        position: relative;
        left: 55%;
      }
      img {
        width: 17rem;
        ${mediaQuery.minDesktop} {
          width: 20rem;
        }
      }
    }
  }

  .right-content {
    padding: 1rem;
    .description-about {
      padding: 2rem 0;
      color: #333;
      h2 {
        font-size: 1.8rem;
        color: #333;
        font-weight: 900;
      }
      p {
        padding: 1rem;
      }
    }
  }
`;
