import React from "react";
import AntCarousel from "antd/lib/carousel";
import { ImgBackgroundCarousel } from "../../images";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useDevice } from "../../hooks";
import { mediaQuery } from "../../styles/constants/mediaQuery";
import { ButtonPrimary } from "../ui";

export const Carousel = ({ onClickVisibleFormContact }) => {
  const { isMobile } = useDevice();

  return (
    <Container>
      <AntCarousel
        autoplay
        autoplaySpeed={5 * 1000}
        arrows={!isMobile}
        prevArrow={<FontAwesomeIcon icon={faChevronLeft} />}
        nextArrow={<FontAwesomeIcon icon={faChevronRight} />}
      >
        <WrapperSlide>
          <img
            src={ImgBackgroundCarousel}
            className="img-slide"
            width={isMobile ? "768" : "992"}
            height="670"
            alt="Banner gamont llanta"
          />
          <div className="content-slide-txt">
            <h1 className="title-banner">
              Ayudamos a su auto a salir a la carrera
            </h1>
            <div className="item-button">
              <ButtonPrimary onClick={() => onClickVisibleFormContact()}>
                <div className="content-button">Contactanos</div>
              </ButtonPrimary>
            </div>
          </div>
        </WrapperSlide>
      </AntCarousel>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  ${mediaQuery.minDesktop} {
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
      top: 46%;
    }
    .ant-carousel .slick-next,
    .ant-carousel .slick-next {
      top: 46%;
    }
    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      color: white;
      z-index: 99999;
    }
    .slick-arrow.slick-prev {
      left: 3%;
    }
    .slick-arrow.slick-next {
      right: 3%;
    }
  }
  .slick-dots-bottom {
    bottom: 1px;
    ${mediaQuery.minDesktop} {
      bottom: 4rem;
    }
  }
  .slick-dots {
    display: none !important;
    ${mediaQuery.minTablet} {
      display: flex !important;
    }
    li.slick-active {
      width: 48px;
    }
    li {
      width: 48px;
      button {
        width: 48px;
        height: 7px;
      }
    }
    ${mediaQuery.minTablet} {
      li.slick-active {
        width: 34px;
      }
      li {
        width: 34px;
        button {
          width: 34px;
          height: 5px;
        }
      }
    }
  }
  ${mediaQuery.minDesktop} {
    display: inherit;
  }
`;

const WrapperSlide = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(51, 51, 51, 0.5);
  }

  .img-slide {
    width: 100vw !important;
    height: 35rem !important;
    object-fit: cover;

    ${mediaQuery.minDesktop} {
      height: auto !important;
    }
  }

  .content-slide-txt {
    position: absolute;
    color: #fff;
    z-index: 500;
    width: 100%;
    bottom: 20%;
    text-align: center;

    ${mediaQuery.minMobile} {
      bottom: 20%;
    }

    ${mediaQuery.minDesktop} {
      bottom: 15%;
    }

    .title-banner {
      margin: auto;
      font-family: "Black Ops One", Serif;
      font-size: 2.5rem;
      text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);
      line-height: 3rem;
      color: rgba(255, 255, 255, 0.8);
      padding: 0 0.6rem;

      ${mediaQuery.minTablet} {
        line-height: 4rem;
        font-size: 3.5rem;
      }

      ${mediaQuery.minDesktop} {
        font-size: 6rem;
        line-height: 6.4rem;
      }
    }

    .item-button {
      margin-top: 4rem;

      .content-button {
        display: flex;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
`;
